import React, { ReactNode } from "react";
import colors from "../../../config/theme/colors";
import rounding from "../../../config/theme/rounding";
import { Box, styled, Typography } from "@mui/material";
import AIChatMessageLoading from "./AIChatMessageLoading";
import classNames from "classnames";
import Markdown from "markdown-to-jsx";
import { AIChatMessageBaseProps } from "~/src/types/aiChat";

interface Props extends AIChatMessageBaseProps {
  HeaderActions?: ReactNode;
  error?: boolean;
  roleLabel?: string;
  metadata?: any;
  formatMessageFn?: (content: string, metadata: any) => string;
  markdownOptions?: any;
  className?: string;
  children?: ReactNode;
}

export default function AIChatMessageContentWrapper({
  role,
  content,
  error,
  loading,
  metadata,
  roleLabel,
  markdownOptions,
  className,
  formatMessageFn,
  HeaderActions,
  children,
}: Props) {
  const getDefaultRoleLabel = () => {
    switch (role) {
      case "user":
        return "Me";
      case "assistant":
        return "KnowledgeAI";
      default:
        return "";
    }
  };

  const renderContent = () => {
    if (loading) {
      return <AIChatMessageLoading />;
    }

    return (
      <Box>
        <MessageWrapper>
          <Typography variant="body4" className="ai-chat-message-content" component="div">
            <Markdown
              options={{
                ...markdownOptions,
                overrides: {
                  a: {
                    component: "a",
                    props: {
                      target: "_blank",
                      rel: "noreferrer",
                    },
                  },
                  ...markdownOptions?.overrides,
                },
              }}
            >
              {formatMessageFn ? formatMessageFn(content, metadata) : content}
            </Markdown>
          </Typography>
        </MessageWrapper>
        {children}
      </Box>
    );
  };

  return (
    <MessageContent
      className={classNames(
        "ai-chat-message-content-wrapper",
        error && "-error-message",
        className
      )}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography
          variant="label3"
          fontFamily="Avenir-heavy"
          className="ai-chat-message-role"
        >
          {roleLabel || getDefaultRoleLabel()}
        </Typography>
        {HeaderActions && <Box>{HeaderActions}</Box>}
      </Box>
      {renderContent()}
    </MessageContent>
  );
}

const MessageContent = styled(Box)`
  max-width: calc(100% - 128px);
  border-radius: ${rounding.default};
  background-color: ${colors.grey[50]};
  color: ${colors.text.secondaryDark};
  padding: 1rem;
  opacity: 0;
  animation: message 1s forwards;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: calc(100% - 112px);
  }

  &.-full-width {
    flex: 1;
  }

  &.-error-message {
    background-color: ${colors.error[50]};
  }

  @keyframes message {
    0% {
      opacity: 0;
      transform: translateY(10px);
      scale: 0.95;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      scale: 1;
    }
  }
`;

const MessageWrapper = styled("div")`
  .ai-chat-message-content > div {
    & > *:first-of-type {
      margin-top: 0;
    }

    & > *:last-of-type {
      margin-bottom: 0;
    }

    p {
      margin: 0.5rem 0;
    }

    strong {
      font-family: Avenir-heavy;
    }

    ul,
    ol {
      padding-left: 1rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    ol {
      li {
        padding-bottom: 0.375rem;

        &::marker {
          font-family: Avenir-heavy;
        }
      }
    }

    ul {
      li::marker {
        content: "•  ";
        font-family: Avenir-heavy;
      }
    }

    table {
      border-collapse: collapse;
      border: 1px solid ${colors.grey[200]};
      margin: 1rem 0 !important;
      box-shadow: 0 0 0 1px ${colors.grey[200]};
      border-radius: ${rounding.default};
      overflow: hidden;

      th,
      td {
        border: 1px solid ${colors.grey[200]};
        padding: 0.5rem;
      }

      th {
        background-color: ${colors.grey[50]};
      }
    }
  }
`;
