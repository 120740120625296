import { Theme } from "@mui/material";
import colors from "../../../theme/colors";
import rounding from "../../../theme/rounding";
import { ComponentOverrides } from "../../types";
import { alphaColor } from "../../../../utils";

const buttonOverrides = (theme: Theme): ComponentOverrides<"MuiButton"> => ({
  styleOverrides: {
    root: {
      padding: "1rem 0.5rem",
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
      minWidth: "156px",
      borderRadius: rounding.default,
      transition: "all 0.2s ease-in-out",
      boxShadow: "none !important",

      // inherit from parent
      "& > svg": {
        width: "1.5rem",
        height: "1.5rem",
        path: {
          fill: "currentColor",
        },
      },

      // icon styles
      "& .svg-inline--fa": {
        width: "1rem",
        height: "1rem",
      },

      // root styles
      "&.Mui-disabled": {
        backgroundColor: colors.grey[300],
        color: colors.base.white,
      },
    },

    // variants
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    containedSecondary: {
      background: colors.base.black,
      color: colors.base.white,

      "&:hover": {
        backgroundColor: colors.text.primaryDark,
      },
    },
    outlined: {
      border: `1px solid ${colors.base.outline1}`,
      color: colors.base.outline1,

      "&.Mui-disabled": {
        color: colors.input.disabled,
        border: `1px solid ${colors.input.disabled}`,
        backgroundColor: colors.base.transparent,
      },
    },
    outlinedPrimary: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,

      "&:hover": {
        border: `1px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.ghost,
      },
      ".MuiTouchRipple-root": {
        color: theme.palette.primary.ghostDark,
      },
    },
    outlinedSecondary: {
      border: `1px solid ${colors.base.outline2}`,
      color: colors.base.outline2,
      backgroundColor: colors.base.transparent,

      "&:hover": {
        border: `1px solid ${colors.text.primaryDark}`,
        color: colors.text.primaryDark,
        backgroundColor: alphaColor(colors.text.primaryDark, 0.025),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(colors.text.primaryDark, 0.05),
      },
    },
    outlinedError: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,

      "&:hover": {
        border: `1px solid ${theme.palette.error.dark}`,
        color: theme.palette.error.dark,
        backgroundColor: alphaColor(theme.palette.error.main, 0.05),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(theme.palette.error.main, 0.1),
      },
    },
    outlinedSuccess: {
      color: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.main}`,

      "&:hover": {
        border: `1px solid ${theme.palette.success.dark}`,
        color: theme.palette.success.dark,
        backgroundColor: alphaColor(theme.palette.success.main, 0.05),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(theme.palette.success.main, 0.1),
      },
    },
    outlinedWarning: {
      color: colors.warning.default,
      border: `1px solid ${colors.warning.default}`,

      "&:hover": {
        border: `1px solid ${theme.palette.warning.dark}`,
        color: theme.palette.warning.dark,
        backgroundColor: alphaColor(colors.warning.default, 0.05),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(colors.warning.default, 0.1),
      },
    },
    text: {
      minWidth: "initial",
      backgroundColor: colors.base.transparent,

      "&:disabled": {
        color: colors.grey[300],
        backgroundColor: colors.base.transparent,
      },
    },
    textPrimary: {
      "&:hover": {
        backgroundColor: theme.palette.primary.ghost,
      },
      ".MuiTouchRipple-root": {
        color: theme.palette.primary.ghostDark,
      },
    },
    textSecondary: {
      color: colors.grey[700],

      "&:hover": {
        backgroundColor: alphaColor(colors.grey[700], 0.05),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(colors.grey[700], 0.1),
      },
    },
    textError: {
      color: theme.palette.error.main,

      "&:hover": {
        backgroundColor: alphaColor(theme.palette.error.main, 0.05),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(theme.palette.error.main, 0.1),
      },
    },
    textSuccess: {
      color: theme.palette.success.main,

      "&:hover": {
        backgroundColor: alphaColor(theme.palette.success.main, 0.05),
      },
      ".MuiTouchRipple-root": {
        color: alphaColor(theme.palette.success.main, 0.1),
      },
    },
    // sizes
    sizeSmall: {
      padding: "10px",

      ".MuiButton-startIcon": {
        marginRight: "4px",
        marginTop: "-2px",
        svg: {
          width: "1rem",
          height: "1rem",
        },
      },
    },
    sizeLarge: {
      padding: "20px 8px",
    },
  },
  // custom variants
  variants: [
    {
      props: {
        size: "extraSmall",
      },
      style: {
        height: "32px",
        fontSize: theme.typography.label3.fontSize,
        lineHeight: theme.typography.label3.lineHeight,
        padding: "2px 12px",
        minWidth: "80px",

        ".MuiButton-startIcon": {
          marginRight: "6px",
          svg: {
            width: "0.875rem",
            height: "0.875rem",
          },
        },
      },
    },
    {
      props: {
        variant: "cta",
      },
      style: {
        padding: 0,
        color: theme.palette.primary.main,
        minWidth: 0,
        backgroundColor: "transparent !important",
        ".MuiTouchRipple-root": {
          color: "transparent",
        },
        "&.Mui-loading": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent !important",
        },
        "&.Mui-disabled": {
          color: colors.text.disabledDark,
          backgroundColor: "transparent !important",
        },
      },
    },
    {
      props: {
        variant: "cta",
        color: "secondary",
      },
      style: {
        color: theme.palette.secondary.main,
      },
    },
    {
      props: {
        variant: "cta",
        color: "error",
      },
      style: {
        color: theme.palette.error.main,
      },
    },
    {
      props: {
        variant: "outlined",
        size: "extraSmall",
      },
      style: {
        borderRadius: rounding.default,
      },
    },
  ],
});

export default buttonOverrides;
