import {
  ClickAwayListener,
  IconButton,
  styled,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import IconCircleInfo from "../../../assets/svgs/circle-info.svg?react";
import classNames from "classnames";

interface Props {
  title: string;
  invisible?: boolean;
  allowPropagation?: boolean;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

export default function InfoButton({
  title,
  invisible,
  allowPropagation,
  sx,
  children,
}: Props) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleToggleTooltip = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!allowPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }

    setOpen((prev) => !prev);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        title={title}
        open={open}
        placement="top"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onClose={handleTooltipClose}
        disableInteractive
        sx={sx}
        data-type="info-button"
      >
        <StyledButton
          disableRipple
          className={classNames({ "--invisible": invisible })}
          onClick={handleToggleTooltip}
        >
          {children || <IconCircleInfo />}
        </StyledButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

const StyledButton = styled(IconButton)`
  padding: 0.25rem;

  &.--invisible {
    background: transparent;
    padding-inline: 0;

    &:hover {
      background: transparent;
    }
  }

  svg {
    width: 1.25rem !important;
    height: 1.25rem !important;
    pointer-events: none;
  }
`;
