import React, { forwardRef } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FormControlProps } from "@mui/material/FormControl";
import FormControl from "../FormControl";
import { FormField } from "../../../components/ui/Form";
import { Box, styled } from "@mui/material";
import colors from "../../../config/theme/colors";

export type CustomTextFieldProps = {
  error_message?: string | undefined;
  label?: string;
  optional?: boolean;
  maxLength?: number;
  counter?: boolean;
  formProps?: FormControlProps;
} & TextFieldProps;

const CustomTextField = forwardRef((props: CustomTextFieldProps, ref) => {
  const { formProps, label, optional, ...rest } = props;

  if (label) {
    return (
      <FormField label={label} name={props.name || props.id} optional={optional}>
        <FormControl {...formProps}>
          {/* Forward the ref to TextComponent */}
          <TextComponent inputRef={ref} {...rest} />
        </FormControl>
      </FormField>
    );
  }

  return (
    <FormControl {...formProps}>
      {/* Forward the ref to TextComponent */}
      <TextComponent inputRef={ref} {...rest} />
    </FormControl>
  );
});

CustomTextField.displayName = "TextField";

export default CustomTextField;

const TextComponent = ({ counter, ...props }: CustomTextFieldProps) => {
  const displayHelperText = props.error_message || (counter && props.maxLength);

  return (
    <TextField
      {...props}
      error={!!props.error_message}
      helperText={
        displayHelperText && (
          <Box component="span" sx={{ display: "flex", justifyContent: "space-between" }}>
            <span>{props.error_message}</span>
            {counter && props.maxLength && (
              <CounterText>
                {(props.value as string).length || 0}/{props.maxLength}
              </CounterText>
            )}
          </Box>
        )
      }
      inputProps={{
        ...props.inputProps,
        maxLength: props.maxLength,
      }}
      onBlur={(event) => {
        if (props.onBlur) {
          props.onBlur(event);
        }
      }}
    />
  );
};

const CounterText = styled("span")`
  color: ${colors.text.tertiaryDark};
  margin-right: -0.75rem;
`;
