import { Theme } from "@mui/material";
import { ComponentOverrides } from "../types";
import colors from "../../theme/colors";
import effects from "../../theme/effects";
import rounding from "../../theme/rounding";

const autocompleteOverrides = (theme: Theme): ComponentOverrides<"MuiAutocomplete"> => ({
  styleOverrides: {
    root: {
      border: "none !important",
      borderRadius: rounding.default,

      "&.Mui-expanded": {
        backgroundColor: colors.input.backgroundFocused,
      },
      "&.MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input": {
        fontSize: theme.typography.body4.fontSize,
        lineHeight: theme.typography.body4.lineHeight,
      },
    },
    paper: {
      marginTop: "1rem",
      boxShadow: effects.shadow.xl,
      borderRadius: rounding.default,
    },
    listbox: {
      borderRadius: `${rounding.default} !important`,
      border: `1px solid ${colors.base.outline1}`,
      padding: 0,
    },
    option: {
      lineHeight: "2.25rem",
      borderBottom: `1px solid ${colors.base.outline1}`,

      ".MuiCheckbox-root": {
        padding: 0,
        paddingRight: "0.5rem",
      },
    },
    inputRoot: {
      minHeight: "54px",
    },
    noOptions: {
      fontSize: theme.typography.body4.fontSize,
      lineHeight: theme.typography.body4.lineHeight,
    },
    endAdornment: {
      ".MuiAutocomplete-clearIndicator svg": {
        width: "1.25rem",
        height: "1.25rem",
      },
    },
  },
});

export default autocompleteOverrides;
