import { MOBILE_APP_STORAGE_KEY, PLATFORM_STORAGE_KEY } from "#constants/common";
import { useSearchParams } from "react-router-dom";

export const useIsMobileApp = () => {
  const [searchParams] = useSearchParams();
  const isMobileApp = sessionStorage.getItem(MOBILE_APP_STORAGE_KEY) === "true";
  const platform = searchParams.get(PLATFORM_STORAGE_KEY) as "ios" | "android" | null;

  const isMobileIOS = isMobileApp && platform === "ios";

  return { isMobileApp, isMobileIOS };
};

export const useIgnoreActionOnMobileApp = () => {
  const { isMobileApp } = useIsMobileApp();

  const ignoreActionOnMobileApp = <T>(callback: () => T) => {
    if (!isMobileApp) {
      return callback();
    }
  };

  return { ignoreActionOnMobileApp };
};
