type RGB = { r: number; g: number; b: number };

export const toRem = (px: number) => `${px / 16}rem`;

export const createColorShades = (color: string) => ({
  main: color,
  contrastText: contrastColor(color),
  light: shadeColor(color, 40),
  dark: shadeColor(color, -10),
  ghost: alphaColor(color, 0.05),
  ghostDark: alphaColor(color, 0.1),
});

export const shadeColor = (hexColor: string, amount: number): string => {
  return (
    "#" +
    hexColor
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
};

export const contrastColor = (
  bgColor: string,
  lightColor = "#FFFFFF",
  darkColor = "#000000"
): string => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const alphaColor = (color: string, opacity: number): string => {
  // coerce values so it is between 0 and 1.
  const val = Math.min(Math.max(opacity || 1, 0), 1);
  const rgba = color.replace("#", "");
  const r = parseInt(rgba.substring(0, 2), 16);
  const g = parseInt(rgba.substring(2, 4), 16);
  const b = parseInt(rgba.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${val})`;
};

/* Checks if the color is close to white */
export const isColorTooLight = (hexColor?: string): boolean => {
  const whiteThreshold = 200;

  if (!hexColor) {
    return false;
  }

  const { r, g, b } = hexToRgb(hexColor);

  if (r < whiteThreshold || b < whiteThreshold || g < whiteThreshold) {
    return false;
  }

  return true;
};

export const hexToRgb = (hex: string): RGB => {
  const rgbPairs = hex.substring(1).match(/.{2}/g);

  if (!rgbPairs) {
    throw new Error("Invalid hex color");
  }

  const [r, g, b] = rgbPairs.map((c) => parseInt(c, 16));

  return { r, g, b };
};
