import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import FormLabel from "./FormLabel";

interface Props {
  label?: string;
  name?: string;
  optional?: boolean;
  children?: ReactNode;
}

export default function FormField({ label, name, optional, children }: Props) {
  return (
    <Wrapper>
      {label && <FormLabel label={label} name={name} optional={optional} />}
      {children}
    </Wrapper>
  );
}

export const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
